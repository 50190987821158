var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "app",
    class: {
      isMobile: _vm.isMobile,
      lightTheme: _vm.lightTheme
    },
    attrs: {
      "id": "app"
    },
    on: {
      "dragenter": function dragenter($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.FILE_DRAG_ENTER($event);
      },
      "dragover": function dragover($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return function () {}($event);
      },
      "dragleave": function dragleave($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.FILE_DRAG_EXIT($event);
      },
      "dragend": function dragend($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.END_FILE_DRAG($event);
      },
      "drop": function drop($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.END_FILE_DRAG($event);
      },
      "click": _vm.CLEAR_OVERLAYS
    }
  }, [!_vm.noTopNav ? [_c('TopNav', {
    style: _vm.topNavStyle,
    attrs: {
      "id": "topNav",
      "showBackButton": _vm.showBackButton,
      "showSideItems": _vm.showSideItems
    }
  })] : _vm._e(), _vm.insufficientPermissions ? [_c('div', {
    staticClass: "absolute center",
    attrs: {
      "id": "insufficientPermissions"
    }
  }, [_c('p', [_vm._v("You do not have permission to view this page.")]), _c('PillButton', {
    on: {
      "click": _vm.SIGN_OUT
    }
  }, [_vm._v("Sign Into a Different Account")])], 1)] : _vm._e(), _c('transition', [_vm.hasNavigatedToRouteDelayed && (_vm.navigatingToRoute || !_vm.showPage && !_vm.insufficientPermissions) ? _c('LoadingSpinner', {
    staticClass: "circledLoadingSpinner fixed center",
    attrs: {
      "circled": ""
    }
  }) : _vm._e()], 1), _c('transition', [!_vm.hasNavigatedToRouteDelayed ? _c('div', {
    staticClass: "fullscreen",
    attrs: {
      "id": "hasNotNavigatedToRoute"
    }
  }, [_c('ReachIcon', {
    staticClass: "absolute center",
    attrs: {
      "disabled": !_vm.hasNavigatedToRoute,
      "useGradient": ""
    }
  })], 1) : _vm._e()]), _vm.showPage ? [_c('div', {
    class: {
      navigatingToRoute: _vm.navigatingToRoute
    },
    style: _vm.containerStyle,
    attrs: {
      "id": "container"
    }
  }, [_c('router-view', {
    style: _vm.routerViewStyle
  })], 1)] : _vm._e(), _c('OverlayManager')], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }