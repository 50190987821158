//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState, mapActions } from 'vuex'

import { formatNumber } from '@happstv/shared/util/utils'

import {
  TOP_NAV_HEIGHT,
} from '@/constants/app/appConstants'

import getDeviceInfo from '@/util/app/getDeviceInfo'
import mapGetSets from '@/util/vue/mapGetSets'
import { registerThrottledEventListener, unregisterThrottledEventListener } from '@/util/webview/throttledEventListener'
import performCloudFunction from '@/util/firebase/performCloudFunction'

import TopNav from '@/components/universal/navigation/TopNav.vue'
import OverlayManager from '@/components/app/OverlayManager.vue'

import ReachIcon from '@/assets/svg/app/ReachIcon.vue'

const SimpleDialog = () => import('@/dialogs/universal/SimpleDialog.vue')

export default {
  name: 'Happs',
  components: {
    TopNav,
    OverlayManager,
    ReachIcon,
  },
  data() {
    return {
      TOP_NAV_HEIGHT,

      formatNumber,

      searchOpen: false,

      currentScrollY: 0,

      headerSelectionTarget: undefined,
      headerSelectionWidth: 0,
      headerSelectionRight: 0,
      headerSelectionSquareRightCorner: false,

      hasNavigatedToRoute: false,
      hasNavigatedToRouteDelayed: false,

      hasShownMouseOutAuthDialog: false,

      ongoingOutage: false,
      nonMinorOngoingOutage: false,
      expandOngoingOutage: false,
    }
  },
  computed: {
    ...mapState([
      'signedInUser',
      'signedInUserId',
      'waitingAuth',
      'isMobile',
      'isDev',
      'navigatingToRoute',
      'deviceId',
    ]),
    ...mapGetSets([
      'headerTranslation',
    ]),
    ...mapGetters('auth', [
      'inAuth',
      'authRedirect',
      'pageRequiresAuthRoles',
    ]),
    ...mapState('communityUser', [
      'communityUser',
    ]),
    noTopNav() {
      const { $route } = this
      if (!$route.name) return true
      return $route.matched.some(record => record.meta.noTopNav || (this.isMobile && record.meta.noTopNavOnMobile))
    },
    lightTheme() {
      return this.$route.matched.some(record => record.meta.lightTheme)
    },
    authIndependent() {
      return this.$route.matched.some(record => record.meta.authIndependent)
    },
    insufficientPermissions() {
      const { navigatingToRoute, hasNavigatedToRoute, pageRequiresAuthRoles, signedInUser = {} } = this
      if (!navigatingToRoute || !hasNavigatedToRoute) return false
      return pageRequiresAuthRoles.some(role => !signedInUser[`${role}Permission`])
    },
    showPage() {
      if (this.authRedirect) return false
      if (this.insufficientPermissions) return false
      return true
    },
    containerPadding() {
      const {
        isMobile,
      } = this

      let top = TOP_NAV_HEIGHT

      if (this.$route.matched.some(({ meta }) => meta.noTopNav || (isMobile && meta.noTopNavOnMobile))) {
        top = 0
      }

      return {
        top,
      }
    },
    containerStyle() {
      const { top } = this.containerPadding

      return {
        paddingTop: `${top}px`,
      }
    },
    routerViewStyle() {
      const { top } = this.containerPadding

      return {
        minHeight: `calc(100vh - ${top}px)`,
      }
    },
    showBackButton() {
      if (!this.showPage || !this.hasNavigatedToRoute) return false
      if (this.$route.meta.noBackButton) return false
      if (this.inAuth) return false
      if (this.isMobile && this.searchOpen) return false
      return true
    },
    showSideItems() {
      if (this.$route.meta.noSideItems) return false
      return true
    },
    pageTitle() {
      if (this.inCommunity) return undefined
      const { isMobile } = this
      return this.$route.matched.reduce((prevResult, item) => (isMobile && item.meta.mobilePageTitle) || item.meta.pageTitle || prevResult, '')
    },
    topNavStyle() {
      const result = {}
      if (this.headerTranslation) {
        result.top = `${-this.headerTranslation}px`
      }
      return result
    },
    authToken() {
      return this.$route.query.authToken
    },
  },
  watch: {
    signedInUserId: {
      immediate: true,
      handler(newValue) {
        if (newValue === undefined && this.isDev && !this.$route.query.prodColors) {
          setTimeout(() => {
            if (this.$route.matched.some(({ meta }) => meta.authIndependent || meta.noAutoAuth)) return

            this.$store.dispatch('overlays/ADD_FULLSCREEN_POPUP', {
              component: SimpleDialog,
              pageless: true,
              params: {
                popupPurpose: 'App.devUrlByMistake',
                title: 'Oops! Something went wrong.',
                text: 'It looks like you may have clicked a broken link. Sincere apologies!<br><br><b>You\'re not in any danger, and it\'s our fault, not yours.</b><br><br>To continue to Happs, please navigate to <b>reach.me</b>, either by typing "reach.me" into your browser or by clicking the button below.',
                buttonText: 'GO TO REACH.ME',
                buttonType: 'happsBlue',
                borderColor: 'var(--happsBlue)',
                onButtonClick() {
                  window.location = 'https://reach.me'
                },
              },
            })
          }, 1000)
        }

        if (newValue !== false) {
          const {
            deviceId,
          } = this

          performCloudFunction('recordDeviceSessionStart', {
            deviceId,
            osType: 'b',
            ...getDeviceInfo(),
          })
        }
      },
    },

    authRedirect: {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          const { route, addRouterHistory } = newValue
          if (route) {
            this.$router.isForcedRedirect = true
            await this.$router[addRouterHistory ? 'pushOverrideAllowed' : 'replaceOverrideAllowed'](route)
            this.$router.isForcedRedirect = false
          }
        }
      },
    },

    authToken: {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          const { $route } = this
          this.$router.replace({ ...$route, query: { ...$route.query, authToken: undefined } })
          this.$store.dispatch('auth/SIGN_IN_WITH_TOKEN', newValue)
        }
      },
    },

    currentScrollY(newValue, oldValue) {
      if (newValue === oldValue) return

      if (this.isMobile) {
        const headerTranslation = Math.max(0, Math.min(newValue, Math.min(TOP_NAV_HEIGHT, this.headerTranslation + newValue - oldValue)))
        if (headerTranslation !== this.headerTranslation) this.headerTranslation = headerTranslation
      }
    },
    isMobile(newValue) {
      if (!newValue) this.headerTranslation = 0
    },

    navigatingToRoute: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          this.hasNavigatedToRoute = true
          setTimeout(() => {
            this.hasNavigatedToRouteDelayed = true
          }, 1000)
        }
      },
    },
  },
  methods: {
    ...mapActions([
      'FILE_DRAG_ENTER',
      'FILE_DRAG_EXIT',
      'END_FILE_DRAG',
    ]),
    ...mapActions('overlays', [
      'CLEAR_OVERLAYS',
    ]),
    ...mapActions('auth', [
      'SIGN_OUT',
    ]),

    onWindowResize({ innerWidth, innerHeight }) {
      const isMobile = innerWidth < 800 || innerHeight < 500
      if (isMobile !== this.isMobile) this.$store.commit('SET_IS_MOBILE', isMobile)
    },
    detectWebview() {
      const queryParams = new URLSearchParams(window.location.search)
      if (queryParams.has('webview')) {
        switch(queryParams.get('webview')) {
          case '1':
          case 'true':
            this.$store.dispatch('SET_IS_WEBVIEW', true)
        }
      }
    },
    onWindowScroll() {
      this.currentScrollY = window.scrollY
    },

    ongoingOutageClicked(event) {
      if (this.expandOngoingOutage) return

      event.preventDefault()
      this.expandOngoingOutage = true
    },
  },
  async mounted() {
    window.addEventListener('scroll', this.onWindowScroll)
    this.listener = registerThrottledEventListener({ resize: true, immediate: true }, this.onWindowResize)
    this.detectWebview()

    this.$store.dispatch('pushNotifications/INIT_PUSH_NOTIFICATIONS')
    this.$store.dispatch('connectedAccounts/GET_EXTERNAL_PLATFORMS_INFO')

    // must be done on nextTick to ensure isMobile has been set
    this.$nextTick(() => this.$store.dispatch('analytics/LOG_EVENT', { name: 'session_started' }))

    this.$store.dispatch('analytics/LOG_PIXEL', { name: 'PageView' })
    this.$store.dispatch('analytics/LOG_GTAG', { name: 'viewed_site' })
  },
  destroyed() {
    window.removeEventListener('scroll', this.onWindowScroll)
    unregisterThrottledEventListener(this.listener)
  },
}
