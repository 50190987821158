var render = function () {
  var _class;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tooltip",
    on: {
      "mouseenter": function mouseenter($event) {
        _vm.isHovered = true;
      },
      "mouseleave": function mouseleave($event) {
        _vm.isHovered = false;
      }
    }
  }, [_c('transition', [_vm.showProcessed ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.clickOutside,
      expression: "clickOutside"
    }],
    class: (_class = {}, _class[_vm.direction] = true, _class),
    style: _vm.tooltipFrameStyle,
    attrs: {
      "id": "tooltipFrame"
    }
  }, [_c('div', {
    style: _vm.tooltipBubbleStyle,
    attrs: {
      "id": "tooltipBubble"
    }
  }, [_vm._t("default")], 2), _c('div', {
    style: {
      borderColor: _vm.borderColor || _vm.backgroundColor,
      transform: "translateX(".concat(_vm.triangleHorizontalOffset, ")")
    },
    attrs: {
      "id": "tooltipTriangle"
    }
  })]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }