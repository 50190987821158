var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "publicCreatorList",
    class: {
      isMobile: _vm.isMobile
    }
  }, [!_vm.creatorList || !_vm.mediaKitList ? _c('LoadingSpinner', {
    staticClass: "absolute center",
    attrs: {
      "type": "greyscaleLight"
    }
  }) : [_vm.agencyData.logoPath ? _c('div', {
    staticClass: "agencyHeader"
  }, [_c('ik-image', {
    staticClass: "agencyLogo",
    attrs: {
      "path": _vm.agencyData.logoPath,
      "transformation": [{
        height: 90
      }],
      "alt": "Logo"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "topBar"
  }, [_c('div', {
    staticClass: "listTitle"
  }, [_c('p', [_vm._v(_vm._s(_vm.creatorList.title))])]), _c('div', {
    staticClass: "listTotals themeListItem themeExtraPadding"
  }, [_c('div', [_c('p', [_vm._v("Creators")]), _vm.mediaKitList === undefined ? _c('LoadingSpinner', {
    staticStyle: {
      "height": "20px",
      "width": "20px"
    },
    attrs: {
      "type": "greyscaleDark"
    }
  }) : _c('p', [_vm._v(_vm._s(_vm.mediaKitList.length))])], 1), _c('div', [_c('p', [_vm._v("Total Audience")]), _c('div', {
    staticClass: "totalFollowers"
  }, _vm._l(Object.entries(_vm.aggregatedFollowersByPlatform), function (_ref, i) {
    var platform = _ref[0],
        followerCount = _ref[1];
    return _c('div', {
      key: i,
      staticClass: "totalFollowerPlatform"
    }, [_vm.getPlatformIcon(platform) ? _c(_vm.getPlatformIcon(platform), {
      tag: "component",
      staticClass: "platformIcon",
      attrs: {
        "color": "#000"
      }
    }) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.numberMagnitudeString(followerCount)))])], 1);
  }), 0)])])]), _c('div', {
    staticClass: "creatorItemList"
  }, _vm._l(_vm.mediaKitList, function (mediaKit, i) {
    return _c('div', {
      key: i,
      staticClass: "creatorItem themeListItem themeExtraPadding clickable",
      on: {
        "click": function click($event) {
          return _vm.openMediaKit(mediaKit);
        }
      }
    }, [_c('div', {
      staticClass: "creatorInfo"
    }, [_c('UserProfilePhoto', {
      staticClass: "mediaKitImg",
      attrs: {
        "user": mediaKit,
        "forceMediumQuality": ""
      }
    }), _c('div', {
      staticClass: "creatorMainSection"
    }, [_c('div', {
      staticClass: "creatorTopLine"
    }, [_c('h3', [_vm._v(_vm._s(mediaKit.fullName))]), mediaKit.location ? _c('div', {
      staticClass: "creatorLocation"
    }, [_c('MapPinIcon', {
      attrs: {
        "color": "#000"
      }
    }), _c('span', [_vm._v(_vm._s(mediaKit.location))])], 1) : _vm._e()]), _c('div', {
      staticClass: "creatorAccountList"
    }, _vm._l(_vm.creatorAccountsByMediaKit[mediaKit.id], function (account, i) {
      return _c('div', {
        key: i,
        staticClass: "creatorAccount themeListItem"
      }, [_c('div', {
        staticClass: "creatorAccountTopLine"
      }, [_vm.getPlatformIcon(account.platform) ? _c(_vm.getPlatformIcon(account.platform), {
        tag: "component",
        staticClass: "platformIcon",
        class: {
          creatorAccountIconLarge: !_vm.hasMetrics(account)
        },
        attrs: {
          "color": "#000"
        }
      }) : _vm._e(), _c('p', {
        staticClass: "creatorAccountName",
        class: {
          creatorAccountNameLarge: !_vm.hasMetrics(account)
        }
      }, [account.platform === _vm.YOUTUBE_NATIVE && account.displayName ? [_vm._v(_vm._s(account.displayName))] : account.username ? [_vm._v("@" + _vm._s(account.username))] : account.displayName ? [_vm._v(_vm._s(account.displayName))] : _vm._e()], 2)], 1), _vm.hasMetrics(account) ? _c('div', {
        staticClass: "creatorAccountMetrics"
      }, _vm._l(_vm.ACCOUNT_METRICS_TO_DISPLAY, function (_ref2) {
        var key = _ref2.key,
            label = _ref2.label;
        return _c('div', {
          key: key,
          staticClass: "metric"
        }, [account[key] ? [_c('b', [_vm._v(_vm._s(_vm.numberMagnitudeString(account[key])))]), _c('span', [_vm._v(_vm._s(label))])] : _vm._e()], 2);
      }), 0) : _vm._e()]);
    }), 0)])], 1), _c('div', {
      staticClass: "creatorRateSection"
    }, [!_vm.isMobile ? _c('p', [_vm._v("click for more info")]) : _vm._e(), _vm.checkShouldShowRate(mediaKit) ? _c('div', {
      staticClass: "creatorRate"
    }, [_c('p', [_vm._v("Rate")]), _c('p', [_vm._v(_vm._s(_vm.getDeliverableRateString(mediaKit)))])]) : _vm._e(), _vm.isMobile ? _c('p', [_vm._v("tap for more info")]) : _vm._e()])]);
  }), 0), _c('div', {
    staticStyle: {
      "flex": "1"
    }
  }), _c('div', {
    staticClass: "poweredBy"
  }, [_c('p', [_vm._v("Powered by")]), _c('ReachLogo', {
    staticClass: "reachLogo",
    attrs: {
      "title": "Reach",
      "alt": "Reach",
      "color": "#000"
    }
  })], 1)], _c('transition', {
    attrs: {
      "appear": "",
      "duration": 300
    }
  }, [_vm.mediaKitPreview ? _c('PopupLayer', {
    key: "mediaKitOverlay",
    staticClass: "popupLayer fillScreen mediaKitOverlay",
    attrs: {
      "component": _vm.PublicMediaKitFrame,
      "params": {
        mediaKit: _vm.mediaKitPreview
      },
      "popupType": "dialog"
    },
    on: {
      "close": _vm.closeMediaKit
    }
  }) : _vm._e()], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }