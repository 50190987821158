//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { getCol, docIdKey } from '@happstv/shared/util/firebase/firestoreUtils'
import { AGENCY_MEDIA_KITS_COL_PATH } from '@happstv/shared/util/firebase/firestorePaths'
import { numberMagnitudeString, formatNumber } from '@happstv/shared/util/utils'
import { TIKTOK_NATIVE, INSTAGRAM_NATIVE, YOUTUBE_NATIVE, SNAPCHAT_NATIVE } from '@happstv/shared/util/multicastingConstants'

import ReachLogo from '@/assets/svg/app/ReachLogo.vue'
import UserProfilePhoto from '@/components/user/UserProfilePhoto.vue'
import PopupLayer from '@/components/universal/overlapping/PopupLayer.vue'
import MapPinIcon from '@/assets/svg/indicators/MapPinIcon.vue'
import TiktokIcon from '@/assets/svg/social/external/TiktokIcon.vue'
import InstagramIcon from '@/assets/svg/social/external/InstagramIconWhite.vue'
import YoutubeIcon from '@/assets/svg/social/external/YoutubeIcon.vue'
import SnapchatIcon from '@/assets/svg/social/external/SnapchatIcon.vue'

import { CUSTOM_CURRENCY_LIST } from '@/util/console/brandCampaignConsoleUtils'

const PublicMediaKitFrame = () => import('./PublicMediaKitFrame.vue')

const SORT_END_CHAR = 'Ξ' // this char comes after all of the alphanumeric chars

export default {
  name: 'PublicCreatorList',
  props: {
    creatorList: Object,
  },
  components: {
    ReachLogo,
    UserProfilePhoto,
    MapPinIcon,
    PopupLayer,
  },
  computed: {
    ...mapState([
      'isMobile',
    ]),
    agencyData() { return (this.creatorList || {}).agencyData || {} },
    creatorListId() { return (this.creatorList || {}).id },
    creatorAccountsByMediaKit() {
      const { mediaKitList } = this
      if (!mediaKitList) return {}

      return mediaKitList.reduce((acc, item) => {
        const { id, creatorConnectedAccountsInfo = {}, manuallyAddedAccounts = [], hiddenConnectedAccountIds = [] } = item || {}

        const connectedAccounts = Object
          .keys(creatorConnectedAccountsInfo)
          .map(accountId => ({ id: accountId, ...(creatorConnectedAccountsInfo[accountId]) }))
          .filter(({ platform }) => [TIKTOK_NATIVE, INSTAGRAM_NATIVE].includes(platform))
          .sort((a, b) => ((b.averageViewCount || 0) - (a.averageViewCount || 0)) || a.id.localeCompare(b.id))

        // Filter out any manually added accounts that have been authed by the creator
        const filteredManuallyAddedAccounts = manuallyAddedAccounts.filter(({ username, platform, displayName }) => !connectedAccounts.some(({ username: creatorAccountUsername = '', platform: creatorAccountPlatform, displayName: creatorAccountDisplayName = '' }) => {
          if (username) {
            return ((username.toLowerCase() === creatorAccountUsername.toLowerCase()) && (platform === creatorAccountPlatform))
          }
          if (displayName) {
            return ((displayName.toLowerCase() === creatorAccountDisplayName.toLowerCase()) && (platform === creatorAccountPlatform))
          }
          return false
        }))

        // Filter out any accounts that have been authed by the creator, but hidden by the agent on the media kit editor
        const filteredCreatorAccounts = connectedAccounts.filter(({ id }) => !hiddenConnectedAccountIds.includes(id))

        acc[id] = [...filteredCreatorAccounts, ...filteredManuallyAddedAccounts]
        
        return acc
      }, {})
    },
    aggregatedFollowersByPlatform() {
      const { creatorAccountsByMediaKit } = this
      if (!creatorAccountsByMediaKit) return {}

      const result = {}
      Object
        .values(creatorAccountsByMediaKit)
        .flat()
        .filter(({ platform }) => [TIKTOK_NATIVE, INSTAGRAM_NATIVE, YOUTUBE_NATIVE].includes(platform))
        .forEach(({ platform, followerCount }) => {
          if (result[platform] !== undefined && followerCount) result[platform] += followerCount
          else if (followerCount) result[platform] = followerCount
        })

      return result
    },
    hideRates() { return (this.creatorList || {}).hideRates },
  },
  data() {
    return {
      numberMagnitudeString,
      mediaKitList: undefined,
      ACCOUNT_METRICS_TO_DISPLAY: [
        { key: 'followerCount', label: 'followers' },
        { key: 'averageViewCount', label: 'avg views' },
      ],
      mediaKitPreview: undefined,
      PublicMediaKitFrame,
      YOUTUBE_NATIVE,
    }
  },
  watch: {
    creatorListId: {
      immediate: true,
      async handler(creatorListId) {
        if (creatorListId) {
          const { creatorList = {} } = this
          const { agencyId, agentUserId, includeFullRoster, selectedMediaKitIdList = [], isAgencyWide } = creatorList

          if (!includeFullRoster && !selectedMediaKitIdList.length) { // This is because the "in" query breaks if selectedMediaKitIdList is empty
            this.mediaKitList = []
          } else {
            const mediaKitQueryAdditions = (() => {
              if (includeFullRoster) {
                if (isAgencyWide) return q => q.where('agencyId', '==', agencyId)
                return q => q.where('agencyId', '==', agencyId).where('agentUserId', '==', agentUserId)
              }
              return q => q.where((docIdKey()), 'in', selectedMediaKitIdList)
            })()

            const mediaKitsSnap = await getCol(AGENCY_MEDIA_KITS_COL_PATH, q => mediaKitQueryAdditions(q).orderBy('createDate', 'asc'))

            const fullMediaKitList = (mediaKitsSnap.docs || [])
              .map(mediaKit => ({ id: mediaKit.id, ...(mediaKit.data() || {}) }))

            const uniqueIdList = fullMediaKitList
              .map(({ creatorUserId, offPlatformCreatorId }) => creatorUserId || offPlatformCreatorId)
              .filter((id, i, a) => id && a.indexOf(id) === i)

            const filteredAndSortedList = uniqueIdList
              .map((uniqueId) => {
                const mediaKitList = fullMediaKitList
                  .filter(({ creatorUserId, offPlatformCreatorId }) => creatorUserId === uniqueId || offPlatformCreatorId === uniqueId)

                const selectedMediaKit = mediaKitList.find(({ id }) => selectedMediaKitIdList.includes(id)) || mediaKitList[0] // default to the oldest media kit
                return selectedMediaKit
              })
              .sort((a, b) => (a.fullName || SORT_END_CHAR).localeCompare(b.fullName || SORT_END_CHAR) || a.id.localeCompare(b.id))

            this.mediaKitList = filteredAndSortedList
          }
        }
      },
    },
  },
  methods: {
    getPlatformIcon(platform) {
      if (platform) {
        if (platform === TIKTOK_NATIVE) return TiktokIcon
        if (platform === INSTAGRAM_NATIVE) return InstagramIcon
        if (platform === YOUTUBE_NATIVE) return YoutubeIcon
        if (platform === SNAPCHAT_NATIVE) return SnapchatIcon
      }
      return undefined
    },
    getDeliverableRate(mediaKit) {
      const { id: mediaKitId, rateCards = [] } = mediaKit || {}
      const { deliverableTypeId, manualRatesByMediaKit = {}, selectedDeliverableTypeByMediaKit = {} } = this.creatorList
      
      let currency, rate
      if (manualRatesByMediaKit[mediaKitId] !== undefined) { 
        ({ currency, rate } = manualRatesByMediaKit[mediaKitId] || {}) // Override a rate card with a manually set rate
      } else if (selectedDeliverableTypeByMediaKit[mediaKitId] !== undefined) {
        ({ currency, rate } = rateCards.find(({ type = {} }) => type.id === selectedDeliverableTypeByMediaKit[mediaKitId]) || {})
      } else {
        ({ currency, rate } = rateCards.find(({ type = {} }) => type.id === deliverableTypeId) || {})
      }

      return { currency, rate }
    },
    getDeliverableRateString(mediaKit) {
      const { currency, rate } = this.getDeliverableRate(mediaKit)
      
      const selectedCurrency = CUSTOM_CURRENCY_LIST.find(({ key }) => key === currency)
      const selectedCurrencyPrefix = selectedCurrency.prefix || '$'
      const innerRateString = (rate !== undefined) ? formatNumber(rate) : ''
      return `${selectedCurrencyPrefix}${innerRateString}`
    },
    checkShouldShowRate(mediaKit) {
      const { hideRates = false } = this
      if (hideRates) return false

      const { rate } = this.getDeliverableRate(mediaKit)
      return rate !== undefined
    },
    hasMetrics(account) {
      const { ACCOUNT_METRICS_TO_DISPLAY } = this
      return ACCOUNT_METRICS_TO_DISPLAY.some(({ key }) => account[key])
    },
    openMediaKit(mediaKit) { this.mediaKitPreview = mediaKit },
    closeMediaKit() { this.mediaKitPreview = undefined },
  },
}
