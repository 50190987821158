var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "observe-dimensions",
      rawName: "v-observe-dimensions",
      value: _vm.dimensions,
      expression: "dimensions"
    }],
    staticClass: "publicMediaKit",
    class: {
      isMobile: _vm.isMobile
    }
  }, [_c('div', [!_vm.mediaKit || !_vm.creatorAccounts ? _c('LoadingSpinner', {
    staticClass: "absolute center",
    attrs: {
      "type": "greyscaleLight"
    }
  }) : [_vm.agencyData.logoPath && !_vm.hideHeaderAndFooter ? _c('div', {
    staticClass: "agencyHeader"
  }, [_c('ik-image', {
    staticClass: "agencyLogo",
    attrs: {
      "path": _vm.agencyData.logoPath,
      "transformation": [{
        height: 90
      }],
      "alt": "Logo"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "creatorHeader"
  }, [_c('UserProfilePhoto', _vm._b({
    staticClass: "creatorPhoto",
    attrs: {
      "forceMediumQuality": ""
    }
  }, 'UserProfilePhoto', {
    user: _vm.mediaKit
  }, false)), _c('div', {
    staticClass: "creatorInfo"
  }, [_c('p', {
    staticClass: "creatorName"
  }, [_vm._v(_vm._s(_vm.fullName))]), _vm.location ? _c('p', {
    staticClass: "creatorLocation"
  }, [_c('MapPinIcon', {
    attrs: {
      "color": "#000"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.location))])], 1) : _vm._e(), _vm.description ? _c('p', {
    staticClass: "creatorDescription"
  }, [_vm._v(_vm._s(_vm.description))]) : _vm._e(), _vm.tagList.length ? _c('div', {
    staticClass: "tagList horizontalList"
  }, _vm._l(_vm.tagList, function (tag, i) {
    return _c('div', {
      key: i,
      staticClass: "tag themeListItem"
    }, [_vm._v(_vm._s(tag))]);
  }), 0) : _vm._e()])], 1), _vm.fullAccountList.length ? [_c('h3', [_vm._v("Accounts")]), _c('div', {
    staticClass: "horizontalList extraMargin"
  }, _vm._l(_vm.fullAccountList, function (account, i) {
    return _c('a', {
      key: i,
      staticClass: "card themeListItem themeExtraPadding",
      class: {
        clickable: Boolean(account.profileUrl)
      },
      attrs: {
        "href": account.profileUrl,
        "target": "_blank"
      }
    }, [_c('div', {
      staticClass: "cardTitle"
    }, [_vm.getPlatformIcon(account.platform) ? _c(_vm.getPlatformIcon(account.platform), {
      tag: "component",
      staticClass: "platformIcon",
      attrs: {
        "color": "#000"
      }
    }) : _vm._e(), account.platform === _vm.YOUTUBE_NATIVE && account.displayName ? _c('span', [_vm._v(_vm._s(account.displayName))]) : account.username ? _c('span', [_vm._v("@" + _vm._s(account.username))]) : account.displayName ? _c('span', [_vm._v(_vm._s(account.displayName))]) : _vm._e()], 1), _vm.hasMetrics(account) ? _c('div', {
      staticClass: "cardMetrics"
    }, _vm._l(_vm.ACCOUNT_METRICS_TO_DISPLAY, function (_ref) {
      var key = _ref.key,
          label = _ref.label;
      return _c('div', {
        key: key,
        staticClass: "metric"
      }, [account[key] !== undefined ? [_c('p', [_vm._v(_vm._s(label))]), _c('p', [_vm._v(_vm._s(_vm.numberMagnitudeString(account[key])))])] : _vm._e()], 2);
    }), 0) : _vm._e(), account.profileUrl ? _c('OpenInNewWindowIcon', {
      staticClass: "openInNewWindowIcon absolute centerVertical",
      attrs: {
        "color": "#000"
      }
    }) : _vm._e()], 1);
  }), 0)] : _vm._e(), _vm.demographicsAccountList.length ? [_c('h3', [_vm._v("Engaged Audience")]), _c('div', {
    staticClass: "themeListItem themeExtraPadding",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('MediaKitAudienceDemographics', _vm._b({
    attrs: {
      "onLight": ""
    }
  }, 'MediaKitAudienceDemographics', {
    demographicsAccountList: _vm.demographicsAccountList
  }, false))], 1)] : _vm._e(), _vm.rateCardList.length ? [_c('h3', [_vm._v("Rate Cards")]), _c('div', {
    staticClass: "horizontalList extraMargin"
  }, _vm._l(_vm.rateCardList, function (rateCard, i) {
    return _c('div', {
      key: i,
      staticClass: "card themeListItem themeExtraPadding"
    }, [_c('p', {
      staticClass: "cardTitle"
    }, [_vm.getPlatformIcon((rateCard.type || {}).platform) ? _c(_vm.getPlatformIcon((rateCard.type || {}).platform), {
      tag: "component",
      staticClass: "platformIcon",
      attrs: {
        "color": "#000"
      }
    }) : _vm._e(), _c('span', [_vm._v(_vm._s((rateCard.type || {}).text))])], 1), _c('p', {
      staticClass: "cardAmount"
    }, [_vm._v(_vm._s("".concat(_vm.getCurrencyPrefix(rateCard)).concat(_vm.formatNumber(rateCard.rate))))])]);
  }), 0)] : _vm._e(), _c('div', {
    staticStyle: {
      "flex": "1"
    }
  }), !_vm.hideHeaderAndFooter ? _c('div', {
    staticClass: "poweredBy"
  }, [_c('p', [_vm._v("Powered by")]), _c('ReachLogo', {
    staticClass: "reachLogo",
    attrs: {
      "title": "Reach",
      "alt": "Reach",
      "color": "#000"
    }
  })], 1) : _vm._e()]], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }