//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatNumber, numberMagnitudeString } from '@happstv/shared/util/utils'
import { TIKTOK_NATIVE, INSTAGRAM_NATIVE, YOUTUBE_NATIVE, SNAPCHAT_NATIVE } from '@happstv/shared/util/multicastingConstants'

import { CUSTOM_CURRENCY_LIST } from '@/util/console/brandCampaignConsoleUtils'
import { getDemographicsAccountListFromMediaKit } from '@/util/agent/agencyUtils'

import MediaKitAudienceDemographics from '@/components/agent/creator/mediaKit/MediaKitAudienceDemographics.vue'
import ReachLogo from '@/assets/svg/app/ReachLogo.vue'
import UserProfilePhoto from '@/components/user/UserProfilePhoto.vue'

import MapPinIcon from '@/assets/svg/indicators/MapPinIcon.vue'
import TiktokIcon from '@/assets/svg/social/external/TiktokIcon.vue'
import InstagramIcon from '@/assets/svg/social/external/InstagramIconWhite.vue'
import YoutubeIcon from '@/assets/svg/social/external/YoutubeIcon.vue'
import SnapchatIcon from '@/assets/svg/social/external/SnapchatIcon.vue'
import OpenInNewWindowIcon from '@/assets/svg/admin/OpenInNewWindowIcon.vue'

export default {
  name: 'PublicMediaKit',
  components: {
    MediaKitAudienceDemographics,
    UserProfilePhoto,
    ReachLogo,
    MapPinIcon,
    TiktokIcon,
    InstagramIcon,
    YoutubeIcon,
    SnapchatIcon,
    OpenInNewWindowIcon,
  },
  props: {
    mediaKit: Object,
    hideHeaderAndFooter: Boolean,
  },
  data() {
    return {
      YOUTUBE_NATIVE,
      formatNumber,
      numberMagnitudeString,
      ACCOUNT_METRICS_TO_DISPLAY: [
        { key: 'followerCount', label: 'Followers' },
        { key: 'averageViewCount', label: 'Avg. Views' },
      ],

      dimensions: { width: 0 },

      demographicsAccountIndex: 0,
    }
  },
  computed: {
    isMobile() { return this.dimensions.width < 800 }, // this ensures that if the media kit is loaded in a dialog, it always shows the mobile version
    agencyData() { return this.mediaKit?.agencyData || {} },
    creatorUserId() { return this.mediaKit?.creatorUserId },
    fullName() { return this.mediaKit?.fullName },
    location() { return this.mediaKit?.location },
    description() { return this.mediaKit?.description },
    tagList() { return this.mediaKit?.tags || [] },
    rateCardList() { return this.mediaKit?.rateCards || [] },
    demographicsByAccountUeid() { return this.mediaKit?.demographicsByAccountUeid || {} },
    manuallyAddedAccounts() { return this.mediaKit?.manuallyAddedAccounts },
    hiddenConnectedAccountIds() { return this.mediaKit?.hiddenConnectedAccountIds },
    fullAccountList() {
      const { manuallyAddedAccounts = [], creatorAccounts = [], hiddenConnectedAccountIds = [] } = this

      // Filter out any manually added accounts that have been authed by the creator
      const filteredManuallyAddedAccounts = manuallyAddedAccounts.filter(({ username, platform, displayName }) => !creatorAccounts.some(({ username: creatorAccountUsername = '', platform: creatorAccountPlatform, displayName: creatorAccountDisplayName = '' }) => {
        if (username) {
          return ((username.toLowerCase() === creatorAccountUsername.toLowerCase()) && (platform === creatorAccountPlatform))
        }
        if (displayName) {
          return ((displayName.toLowerCase() === creatorAccountDisplayName.toLowerCase()) && (platform === creatorAccountPlatform))
        }
        return false
      }))

      // Filter out any accounts that have been authed by the creator, but hidden by the agent on the media kit editor
      const filteredCreatorAccounts = creatorAccounts.filter(({ id }) => !hiddenConnectedAccountIds.includes(id))

      const combinedAccounts = [...filteredCreatorAccounts, ...filteredManuallyAddedAccounts]

      if (!combinedAccounts.length) return []

      return combinedAccounts
    },
    creatorAccounts() {
      const { creatorConnectedAccounts = [] } = this.mediaKit || {}

      return creatorConnectedAccounts
        .filter(({ platform }) => [TIKTOK_NATIVE, INSTAGRAM_NATIVE].includes(platform))
        .sort((a, b) => ((b.averageViewCount || 0) - (a.averageViewCount || 0)) || a.accountUeid.localeCompare(b.accountUeid))
    },
    demographicsAccountList() {
      return getDemographicsAccountListFromMediaKit(this.mediaKit || {})
    },
  },
  methods: {
    getCurrencyPrefix(rateCard = {}) {
      const { currency } = rateCard
      const selectedCurrency = CUSTOM_CURRENCY_LIST.find(({ key }) => key === currency)
      return selectedCurrency.prefix || '$'
    },
    getPlatformIcon(platform) {
      if (platform) {
        if (platform === TIKTOK_NATIVE) return TiktokIcon
        if (platform === INSTAGRAM_NATIVE) return InstagramIcon
        if (platform === YOUTUBE_NATIVE) return YoutubeIcon
        if (platform === SNAPCHAT_NATIVE) return SnapchatIcon
      }
      return undefined
    },
    hasMetrics(account) {
      const { ACCOUNT_METRICS_TO_DISPLAY } = this
      return ACCOUNT_METRICS_TO_DISPLAY.some(({ key }) => account[key] !== undefined)
    },
  },
}
