var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "topNav",
    class: {
      isMobile: _vm.isMobile
    },
    style: {
      height: "".concat(_vm.TOP_NAV_HEIGHT, "px")
    }
  }, [_c('div', {
    attrs: {
      "id": "topNavCenterSpacer"
    }
  }), _c('div', {
    staticClass: "absolute",
    class: {
      centerHorizontal: !_vm.inDashboard
    },
    attrs: {
      "id": "topNavCenterContents"
    }
  }, [_c('maybe-router-link', {
    staticClass: "clickable",
    attrs: {
      "linkEnabled": Boolean(_vm.logoRoute),
      "to": _vm.logoRoute
    }
  }, [_c('ReachLogo')], 1), _vm.isDev ? _c('p', {
    staticStyle: {
      "font-weight": "100",
      "font-size": "23px"
    }
  }, [_vm._v("DEV")]) : _vm._e()], 1), _vm.inDashboard ? _c('div', [((_vm.coverInfo || {}).brand || {}).logoPath ? _c('ik-image', {
    staticClass: "brandLogo",
    class: {
      mobileBrandLogo: _vm.isMobile
    },
    attrs: {
      "path": ((_vm.coverInfo || {}).brand || {}).logoPath,
      "transformation": [{
        height: 60
      }],
      "alt": "Logo"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.showSideItems && !_vm.isWebview ? [_vm.signedInUserId && !_vm.inAuth ? [_c('div', {
    staticClass: "profileContainer"
  }, [_vm.signedInUser && !_vm.inAuth ? _c('UserProfilePhoto', {
    staticClass: "clickable",
    attrs: {
      "id": "topNavProfilePhoto",
      "user": _vm.signedInUser
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.openMenu($event);
      }
    }
  }) : _vm._e()], 1)] : !_vm.inAuth && !_vm.waitingAuth && !_vm.isMobile && !_vm.inHome && !_vm.inCampaign && _vm.authExists && !_vm.inDashboard ? _c('router-link', {
    attrs: {
      "to": {
        name: 'auth'
      }
    }
  }, [_c('PillButton', {
    attrs: {
      "gradientType": "greyscaleDark"
    }
  }, [_vm._v("Sign In")])], 1) : _vm._e()] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }